import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const StyledDiv = styled.div`
  background: #E7F1EF;
`

const CTASection = () => (
  <StyledDiv className='relative p-4 py-16 z-10 sm:px-10'>
    <div className='sm:w-full lg:w-6/12 xl:w-4/12 mx-auto'>
      <h2 className='font-display text-grolens-green-900 font-extrabold text-4xl sm:text-3xl text-center leading-tight mb-2'>Get Started With GroLens Today</h2>

      <p className='sm:hidden font-body text-2xl text-grolens-green-800 text-center'>Connect with a GroLens expert to discuss how<br className='sm:hidden' /> we can improve your workflow in your facility.</p>
    </div>

    <div className='sm:w-full w-9/12 mx-auto flex justify-center'>
      <Link to='/request-demo'>
        <button className="bg-grolens-green-300 hover:bg-grolens-green-600 text-white text-md font-medium uppercase py-4 px-12 mt-6">
          Request Demo
        </button>
      </Link>
    </div>
  </StyledDiv>
)

export default CTASection