import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 380px;
  background: #f3fbf9;

  @media (max-width: 767px) {
    min-height: 300px;
  }
`

const PageBlogHeaderColorBg = ({ children }) => (
  <StyledDiv>
    {children}
  </StyledDiv>
)

PageBlogHeaderColorBg.propTypes = {
  children: PropTypes.node,
}

export default PageBlogHeaderColorBg