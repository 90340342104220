import React from "react"
import HomeContainer from "../containers/HomeContainer"
import HeadData from "../data/HeadData"

import PageBlogHeaderColorBg from "../headers/PageBlogHeaderColorBg"
import HeaderNav from "../navigation/HeaderNav"
import CTASection from "../sections/CTASection"
import PageTermsSection from "../sections/PageTermsSection"

import CanIcon from '../assets/can58ec8bb8.svg'

export default () => (
  <HomeContainer>
    <HeadData title='Legal Terms & Conditions' />

    <PageBlogHeaderColorBg>
      <HeaderNav isHome />

      <div className='sm:w-11/12 md:w-11/12 lg:w-8/12 xl:w-6/12 flex flex-col items-center mx-auto'>
        <CanIcon className='mt-20 sm:mt-16 w-12 h-12 opacity-50' />
        <h1 className='text-4xl sm:text-2xl text-center font-bold text-grolens-green-900 mt-8 leading-tight'>Legal Terms & Conditions</h1>
      </div>
    </PageBlogHeaderColorBg>

    <PageTermsSection />

    <CTASection />
  </HomeContainer>
)